import React, { useState, useRef } from 'react';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { DocumentViewer as BaseDocumentViewer } from '@/Framework/UI/Organisms/DocumentViewer';
import { canDownload } from '@/dataroom/domain/filesystemPermissions';
import { canUserBulkDownload } from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from './documentViewer.scss';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import PreviewNotAvailable from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/PreviewNotAvailable';
import DataroomRedactionContext, {
  useDataroomRedactionContext,
} from '@/dataroom/application/redaction/DataroomRedactionContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { SidebarMode } from '@/Framework/UI/Organisms/DocumentViewer/SidebarMode';
import { useRedactionPermissions } from '@/dataroom/application/redaction/useRedactionPermissions';

const DocumentViewer = () => {
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();

  const {
    requestDownload,
    download,
  } = useDownloadContext();

  const {
    previewItem,
    defaultSearch,
    defaultSidebarMode,
    resetPreview,
    fileWatermark,
    isFetching,
    isError,
    isRedactionModeEnabled,
    setDefaultSidebarMode,
    isRedactionModeInitial,
  } = useDocumentPreviewContext();

  const redactionActions = useDataroomRedactionContext();

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isRedactionModeVisible, setIsRedactionModeVisible] = useState(false);
  const userCanBulkDownload = canUserBulkDownload(currentUser);
  const { userCanRedactFile } = useRedactionPermissions([previewItem]);

  const {
    dataroom: {
      watermarking,
      stagingWatermarking,
      watermarkingOpacity,
      stagingWatermarkingOpacity,
    },
  } = useDataroomContext();

  const { updateCollection } = useDataroomExplorerContext();

  const {
    listing: {
      resetRedactions,
    },
  } = redactionActions;

  const showWatermark = previewItem?.isStaging ? stagingWatermarking : watermarking;
  const opacity = previewItem?.isStaging ? stagingWatermarkingOpacity : watermarkingOpacity;
  const isRedactionEnabled = isRedactionModeEnabled && userCanRedactFile;
  const isRedactionSaveActionFired = useRef(false);

  if (redactionActions.save.isFetching && isRedactionModeInitial) {
    isRedactionSaveActionFired.current = true;
  }
  const handleFinish = () => {
    setIsDownloadLoading(false);
  };

  const handleDownload = () => {
    setIsDownloadLoading(true);

    requestDownload({
      items: [previewItem],
      onFinish: handleFinish,
    })
      .then(download);
  };

  const handleCloseRedaction = () => {
    setIsRedactionModeVisible(false);
    setDefaultSidebarMode(SidebarMode.None);
    isRedactionEnabled && resetRedactions();
  };

  const handleClose = () => {
    resetPreview();
    handleCloseRedaction();
    isRedactionSaveActionFired.current && updateCollection();
    isRedactionSaveActionFired.current = false;
  };

  if (!previewItem && !isFetching && !isError) return null;

  const documentUrl = isRedactionEnabled && (isRedactionModeVisible || defaultSidebarMode === SidebarMode.Redaction)
    ? dataroomUrl(tenant)
      .getOriginalPreviewUrl(dataroom.name, previewItem?.id)
    : dataroomUrl(tenant)
      .getPreviewUrl(dataroom.name, previewItem?.id);

  return previewItem?.previewable ? (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <BaseDocumentViewer
        fileId={ previewItem?.id }
        documentTitle={ previewItem?.name }
        defaultSearch={ defaultSearch }
        documentUrl={ documentUrl }
        watermarkText={ showWatermark ? [fileWatermark, 'CONFIDENTIAL INFORMATION - DO NOT DISTRIBUTE'] : [] }
        className={ styles.documentPreviewer }
        close={ handleClose }
        onDownload={ handleDownload }
        isDownloadDisabled={ !canDownload(previewItem, userCanBulkDownload, false, 1) }
        watermarkOpacity={ opacity }
        watermarkWidth={ 480 }
        isFetching={ isFetching }
        isDownloadLoading={ isDownloadLoading }
        isCustomError={ isError }
        defaultScale={ SpecialZoomLevel.ActualSize }
        minZoomLevel={ 50 }
        isBlurProtectionEnabled={ dataroom.blurEnabled }
        defaultSidebarMode={ defaultSidebarMode }
        blurNotification="As a protection measure, your file is hidden when you are not interacting with the viewer. To view this file, move your mouse pointer back to this page."
        redactionOptions={ {
          isRedactionEnabled,
          setIsRedactionModeVisible,
          handleCloseRedaction,
          updateCollection,
          ...redactionActions,
        } }
      />
    </PortalWrp>
  ) : (
    <Modal
      title="Preview Not Available"
      isVisible
      className={ styles.previewNotAvailableModal }
      onCloseClicked={ resetPreview }
      dataTest="previewNotAvailableModal"
    >
      { (footerRefCallback) => (
        <PreviewNotAvailable
          item={ previewItem }
          closeModal={ resetPreview }
          footerRefCallback={ footerRefCallback }
        />
      ) }
    </Modal>
  );
};

export default (props) => (
  <DataroomRedactionContext>
    <DocumentViewer { ...props } />
  </DataroomRedactionContext>
);
