import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IRedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';
import { IRedactedFilesListItem } from '@/dataroom/domain/vo/redaction/RedactedFilesType';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IRedactionAreaListItem } from '@/dataroom/domain/vo/redaction/RedactionAreaType';
import { IEntry, IRedaction } from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class RedactionRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  getRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ collection: IRedaction[] }> => {
    const request = new Request('redaction.list.pending', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRedactionArea = async (
    payload: {
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
      search: string,
      filesystemArea: string,
      dataroomId: number,
    },
  ): Promise<{ collection: IRedactionAreaListItem[] }> => {
    const request = new Request('redaction.redaction_area.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRedactedFiles = async (
    payload: {
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
      search: string,
      filesystemArea: string,
      dataroomId: number,
    },
  ): Promise<{ collection: IRedactedFilesListItem[] }> => {
    const request = new Request('redaction.redacted_files.list', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  applyAllRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
      onFinish: () => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const notificationId = `redaction-file-${ requestPayload.fileId }`;
    const request = new Request('redaction.apply_all', {
      ...requestPayload,
    });

    const subscribeReq = new Request('redaction.apply.listen', { notificationId });

    const subscription = await this.socketClient()
      .subscribe(subscribeReq);

    subscription
      .on('redaction.apply.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('redaction.apply.error', () => {
        subscription.cancel();
        onError();
      });

    try {
      const response = await this.rpc()
        .call<RpcSuccess>(request);

      return response.getResult();
    } catch (error) {
      subscription.cancel();
      throw error;
    }
  };

  search = async (payload: {
    dataroomId: number,
    fileId: number,
    search: string,
  }): Promise<{ collection: IEntry[] }> => {
    const request = new Request('redaction.search', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  saveAction = async (
    payload: {
      dataroomId: number,
      fileId: number,
      actions: IRedactionAction[],
    },
  ): Promise<void> => {
    const request = new Request('redaction.action.save', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  removeAllRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('redaction.redactions.remove_all', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  removeAppliedRedactions = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('redaction.delete_redacted', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  unredactSelectedFiles = async (
    payload: {
      dataroomId: number,
      fileIds: number[],
      uuid: string,
      onFinish: ({
        success,
        fail,
      }: { success: number, fail: number }) => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.unredact.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe(subscribeReq);

    subscription
      .on('redaction.bulk.unredact.listen.completed', ({ params: { payload } }) => {
        subscription.cancel();
        onFinish(payload);
      });
  };

  unredactAllFiles = async (
    payload: {
      dataroomId: number,
      filesystemArea: string,
      search: string,
      uuid: string,
      onFinish: ({
        success,
        fail,
      }: { success: number, fail: number }) => void,
      onError: () => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('redaction.bulk.unredact_all.listen', {
      ...requestPayload,
    });

    const subscription = await this.socketClient()
      .subscribe(subscribeReq);

    subscription
      .on('redaction.bulk.unredact_all.listen.completed', ({ params: { payload } }) => {
        subscription.cancel();
        onFinish(payload);
      });
  };

  checkFilesForRedactByKeyword = async (
    payload: {
      dataroomId: number,
      items: Pick<IFilesystemListItem, 'id' | 'type'>[],
    }): Promise<{ validation: boolean }> => {
    const request = new Request('redaction.check_count_files', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  scanByKeyword = async (
    payload: {
      search: string,
      dataroomId: number,
      items: Pick<IFilesystemListItem, 'id' | 'type'>[],
    }): Promise<{ validation: boolean }> => {
    const request = new Request('redaction.bulk_keyword_search', payload);

    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default RedactionRepository;
